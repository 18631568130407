import { cloneDeep } from 'lodash';
import { Space, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Campus } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvCheckbox } from '../../../common/components/LrvCheckbox/LrvCheckbox';
import { stockingPhaseTypes, unitStatuses, CONTAINER_LABEL } from '../../../config/commons';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './SubHeader.module.scss';
import * as parameterChartSlice from './parameterChartSlice';
import { DataCustomizable, LayoutCustomizable } from './interfaces';
import { dateRangeOptions, hasEditSettingsPermission, hasNewItem, existsItemsAvailables } from './helpers';

const { Option } = Select;

export const SubHeader = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    units,
    containers,
    modules,
    stockings,
    unitId,
    moduleId,
    containerId,
    stockingId,
    enableEditing,
    parameterChart,
    parameterChartCustomizable,
    companyStockingParameters,
    parameterWithoutFrequencies,
    selectedParameterName,
    selectedDateOption,
    isDownloadingFile,
  } = useSelector((state: Store) => state.parameterChart);

  const { phaseType: phaseTypeSelected, company } = useSelector((state: Store) => state.header);

  const { defaultData: parameterChartData, dataCustomizable } = parameterChart;
  const { growthRates, totalFeed } = parameterChart.data;

  const theme = getCurrentTheme();

  const resetSelectedParameters = () => {
    dispatch(parameterChartSlice.setSelectedParameterName(undefined));
    dispatch(parameterChartSlice.setSelectedDateOption(dateRangeOptions.WHOLE_CROP.value));
    dispatch(parameterChartSlice.setParameterWithoutFrequencies({ unit: '', values: [] }));
    dispatch(parameterChartSlice.setParameterWithFrequencies({}));
  };

  //#region Commons functions
  const renderUnitsFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campus'
        showSearch
        theme={theme}
        className={className}
        value={unitId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        onChange={(value: string) => {
          const campus: Campus | undefined = units.find((campus: Campus) => campus._id === value);
          if (!campus?._id) {
            return;
          }

          resetSelectedParameters();

          dispatch(parameterChartSlice.setUnitId(value));
          dispatch(parameterChartSlice.setModuleId(undefined));
          dispatch(parameterChartSlice.setContainerId(undefined));
          dispatch(parameterChartSlice.setStockingId(undefined));

          dispatch(parameterChartSlice.fetchModules({ unitId: value }));
        }}
        filterOption={filterOptionSelect}
      >
        {units.map((unit) => {
          return (
            <Option key={unit._id} value={unit._id} label={unit.name}>
              {unit.name}
              {
                unit.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag type='info'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModulesFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        optionFilterProp='children'
        disabled={!unitId}
        onChange={(value) => {
          if (!unitId) {
            return;
          }

          resetSelectedParameters();

          dispatch(parameterChartSlice.setModuleId(value));
          dispatch(parameterChartSlice.setContainerId(undefined));
          dispatch(parameterChartSlice.setStockingId(undefined));

          dispatch(parameterChartSlice.fetchContainers({ unitId, moduleId: value }));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {modules.filter((module) => module.phaseType === phaseTypeSelected).map((module) =>
          <Option key={module._id} value={module._id}>{module.name}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderTanksFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_tanks'
        value={containerId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
        placeholder={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
        optionFilterProp='children'
        onChange={(value) => {
          if (!unitId || !moduleId) {
            return;
          }

          resetSelectedParameters();

          dispatch(parameterChartSlice.setContainerId(value));
          dispatch(parameterChartSlice.setStockingId(undefined));

          dispatch(parameterChartSlice.fetchStockings({ unitId, moduleId, containerId: value, companyId: company._id }));
        }}
        filterOption={filterOptionSelect}
        disabled={!(unitId && moduleId)}
        dropdownMatchSelectWidth={false}
      >
        {containers.map((container) => <Option key={container._id} value={container._id}>{container.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderStockingsFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_stockings'
        value={stockingId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t('stockings.stocking')}
        placeholder={t('stockings.stocking')}
        optionFilterProp='children'
        onChange={(value) => {
          resetSelectedParameters();

          dispatch(parameterChartSlice.setStockingId(value));
          dispatch(parameterChartSlice.fetchStockingParameterChart({ companyId: company._id, stockingId: value }));
        }}
        filterOption={filterOptionSelect}
        disabled={!(unitId && moduleId && containerId)}
        dropdownMatchSelectWidth={false}
      >
        {stockings.map((stocking) => <Option key={stocking._id} value={stocking._id}>{stocking.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(parameterChartSlice.resetFilters());
        }}
      />
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<div className={styles.title}>{t('stockings.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(parameterChartSlice.resetFilters());
            },
          }}
        >
          <Space
            className={styles.bodyPanel}
            direction='vertical'
          >
            {renderUnitsFilter({ theme: 'light' })}
            {renderModulesFilter({ theme: 'light' })}
            {renderTanksFilter({ theme: 'light' })}
            {renderStockingsFilter({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderEnableEditingOption = () => {
    if (!hasEditSettingsPermission) {
      return;
    }

    return (
      <LrvCheckbox
        theme={theme}
        onChange={event => dispatch(parameterChartSlice.setEnableEditing(event.target.checked))}
        checked={enableEditing}
        disabled={!stockingId}
      >
        {t('production.parameter.enableEditing')}
      </LrvCheckbox>
    );
  };

  const saveParameterChart = () => {
    const layoutCopy = cloneDeep(dataCustomizable);
    const data = layoutCopy.data.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y).map((item) => {
      const data: LayoutCustomizable = {
        w: item.w,
        h: item.h,
        x: item.x,
        y: item.y,
        i: item.i,
        parameters: item.parameters,
      };

      return data;
    });

    const config: DataCustomizable = {
      data,
    };

    if (!parameterChartCustomizable._id) {
      dispatch(parameterChartSlice.createParameterChart({ companyId: company._id, config }));
      return;
    }

    dispatch(parameterChartSlice.updateParameterChart({ _id: parameterChartCustomizable._id, companyId: company._id, config }));
  };

  const addNewParameter = () => {
    dispatch(parameterChartSlice.setShouldAddNewData(true));
    dispatch(parameterChartSlice.setShowDataCustomizableModal(true));
  };

  const renderSaveButton = () => {
    if (!hasEditSettingsPermission) {
      return;
    }

    return (
      <div className={styles.containerButton}>
        <ActionButton
          id='btn_save_parameters_chart'
          type='primary'
          disabled={!enableEditing || hasNewItem({ layoutCustomizable: dataCustomizable.data })}
          onClick={saveParameterChart}
        >
          {t('production.parameter.save')}
        </ActionButton>
      </div>
    );
  };

  const renderAddButton = () => {
    if (!hasEditSettingsPermission || phaseTypeSelected !== stockingPhaseTypes.ADULT) {
      return;
    }

    return (
      <div className={styles.containerButton}>
        <ActionButton
          id='btn_add_parameter_chart'
          type='primary'
          disabled={!enableEditing || !existsItemsAvailables({ layoutCustomizable: dataCustomizable.data, defaultData: parameterChartData }) || hasNewItem({ layoutCustomizable: dataCustomizable.data })}
          onClick={addNewParameter}
        >
          {t('production.parameter.add')}
        </ActionButton>
      </div>
    );
  };

  const generatePdf = () => {
    if (!stockingId || !selectedParameterName) {
      return;
    }

    const params = {
      stockingId: stockingId,
      stockingName: stockingId,
      parameter: selectedParameterName,
      dateOption: selectedDateOption,
    };

    dispatch(parameterChartSlice.fetchUrlStockingParameterPdf(params));
  };


  const renderStockingParameterReport = () => {
    return (
      <IconButton
        id='xlsx_report_button'
        className={styles.reportButton}
        icon={<Icon name='download' theme={theme} />}
        onClick={generatePdf}
        placement='bottomLeft'
        tooltipText={t('stockingParameter.download')}
        disabled={!stockingId || companyStockingParameters.length === 0 || (growthRates.values.length === 0 && totalFeed.values.length === 0 && (parameterWithoutFrequencies.values.length === 0 || Object.keys(parameterWithoutFrequencies).length === 0))}
        loading={isDownloadingFile}
      />
    );
  };

  return (
    <Row className={styles.rowHeader} >
      <Space className={styles.filters} align='end'>
        {renderUnitsFilter({ className: styles.select, theme })}
        {renderModulesFilter({ className: styles.select, theme })}
        {renderTanksFilter({ className: styles.select, theme })}
        {renderStockingsFilter({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>

      {renderSidePanel()}

      <Row className={styles.rowRight}>
        <Space align='center' size='middle'>
          {renderEnableEditingOption()}
          {renderSaveButton()}
          {renderAddButton()}
          {renderStockingParameterReport()}
        </Space>
      </Row>
    </Row>
  );
};
