import { Row } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { dollarUS } from '../../utils/strings';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { formatLongDateWithZone, formatMonthYear } from '../../utils/date';
import { isFinanceRole, onboardingTypes, plansTypes, transferStatus } from '../../config/commons';
import { setExistsUserData, setStepIndex, setOnboardingType, setShouldRun, setRun } from '../../common/components/Onboarding/OnboardingSlice';

import { Payment } from './interfaces';
import styles from './Payments.module.scss';
import { clearLocalStorage } from './helpers';
import * as paymentSlice from './paymentSlice';
import PaymentSubHeader from './PaymentSubHeader';
import NewPrepaidPayment from './NewPrepaidPayment';
import NewPostpaidPayment from './NewPostpaidPayment';
import NewPostpaidPaymentCsv from './NewPostpaidPaymentCsv';

export default function Payments () {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const clientTransactionId = new URLSearchParams(search).get('clientTransactionId');

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();

  const [paymentDate, setPaymentDate] = useState<string[]>([]);
  const [prices, setPrices] = useState<number[]>([]);

  const {
    currentPage,
    isLoading,
    list: payments,
    limit,
    total,
  } = useSelector((state: Store) => state.payments);

  const {
    user: onboardingUserData,
    existsUserData,
  } = useSelector((state: Store) => state.onboarding);

  const { company } = useSelector((state: Store) => state.header);

  const isAssignedCompany = localStorage.getItem('isAssignedCompany');
  const paymentType = localStorage.getItem('paymentType');
  const months = localStorage.getItem('months');
  const priceList = localStorage.getItem('prices');
  const amountAnalysis = localStorage.getItem('quota');

  const theme = getCurrentTheme();

  useEffect(() => {
    const date = months ? JSON.parse(months) : null;
    if (!date) {
      return;
    }

    const isValidMonths = date.every((month: string) => month.trim() !== '');

    if (isValidMonths) {
      setPaymentDate(date);
    }
  }, [months]);

  useEffect(() => {
    const prices = priceList ? JSON.parse(priceList) : null;
    if (!prices) {
      return;
    }

    setPrices(prices);
  }, [priceList]);

  useEffect(() => {
    if (id && id !== '0' && clientTransactionId && paymentType && months && paymentDate?.length && priceList && prices?.length && amountAnalysis) {
      dispatch(paymentSlice.fetchConfirm({ id, clientTransactionId, paymentType, months: paymentDate, prices, quota: parseInt(amountAnalysis) }));
      clearLocalStorage();
    }
  }, [dispatch, id, clientTransactionId, paymentType, months, paymentDate, priceList, prices, amountAnalysis]);

  useEffect(() => {
    if (isAssignedCompany === 'true') {
      window.location.href = '/unauthorized';
    }
  }, [isAssignedCompany]);

  useEffect(() => {
    return () => {
      dispatch(setExistsUserData(false));
      clearLocalStorage();
    };
  }, [dispatch]);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.paypalSection) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.PAYPAL_SECTION));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'payments.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!company._id || months) {
      return;
    }

    dispatch(paymentSlice.fetchPayments({ page: 1, companyId: company._id }));
  }, [dispatch, company, months]);

  const onChangePage = (page: number) => {
    dispatch(paymentSlice.setCurrentPage(page));
    dispatch(paymentSlice.fetchPayments({ page: page, companyId: company._id }));
  };

  const columns: ColumnsType<Payment> = [
    {
      key: 1,
      title: t('payments.userPayer'),
      width: '19%',
      render: (_, record: Payment) => record.userPayer ? record.userPayer?.firstName + ' ' + record.userPayer?.lastName : '',
    },
    {
      key: 2,
      title: t('payments.email'),
      width: '19%',
      responsive: ['lg'] as Breakpoint[],
      render: (_, record: Payment) => record.userPayer?.email,
    },
    {
      key: 3,
      title: t('payments.amount'),
      width: '8%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: Payment) => dollarUS.format(record.amount),
    },
    {
      key: 4,
      title: t('payments.paymentDate'),
      width: '12%',
      render: (_, record: Payment) => formatLongDateWithZone(record.createdAt),
    },
    {
      key: 5,
      title: t('payments.period'),
      dataIndex: 'period',
      width: '10%',
      render: (_, record: Payment) => record.type === plansTypes.PREPAID ? 'N/A' : formatMonthYear(record.paymentDate),
    },
    {
      key: 6,
      title: t('payments.paymentType'),
      dataIndex: 'type',
      width: '11%',
      responsive: ['sm'] as Breakpoint[],
      render: (_, record: Payment) => {
        let typePayment = '';
        switch (record.type) {
          case plansTypes.PREPAID:
            typePayment = t('payments.prepaid');
            break;

          case plansTypes.POSTPAID:
            typePayment = t('payments.postpaid');
            break;
        }

        return typePayment;
      },
    },
    {
      key: 7,
      title: t('payments.status'),
      dataIndex: 'status',
      width: '11%',
      responsive: ['sm'] as Breakpoint[],
      render: (_, record: Payment) => {
        if (!record.status) {
          return null;
        }

        return (
          <div id={record._id} className={styles.status}>
            {getStatusIcon(record.status)}
            <div className={styles.name}> {renderPaymentStatus(record.status)} </div>
          </div>
        );
      }
    },
    {
      key: 8,
      title: t('payments.description'),
      dataIndex: 'comment',
      width: '18%',
      responsive: ['lg'] as Breakpoint[],
    },
  ];

  function getStatusIcon (status: string) {
    let icon;
    switch (status) {
      case transferStatus.PENDING_REVIEW:
        icon = <Icon name='time' className={styles.pendingReviewIcon} />;
        break;

      case transferStatus.APPROVED:
        icon = <Icon name='checkbox-circle' className={styles.approvedIcon} />;
        break;

      case transferStatus.REJECTED:
        icon = <Icon name='close-circle' className={styles.rejectedIcon} />;
        break;
    }

    return icon;
  }

  function renderPaymentStatus (status: string) {
    let value = '';
    switch (status) {
      case transferStatus.PENDING_REVIEW:
        value = t('payments.detail.statuses.approvalPending');
        break;

      case transferStatus.APPROVED:
        value = t('payments.detail.statuses.approved');
        break;

      case transferStatus.REJECTED:
        value = t('payments.detail.statuses.rejected');
        break;
    }

    return (
      <div>{value}</div>
    );
  }

  return (
    <div className={styles.payments}>
      <PaymentSubHeader />
      <Row>
        <LrvTable
          id='table_payments'
          className={styles.table}
          rowClassName={(record) => {
            if (record.isTransfer && isFinanceRole()) {
              return styles.rowTable;
            }
            return '';
          }}
          columns={columns}
          dataSource={payments}
          loading={isLoading}
          scroll={payments.length === 0 ? undefined : { y: 'calc(100vh - 246px)' }}
          theme={theme}
          onRow={(record: Payment) => {
            return {
              onClick: () => {
                if (record.isTransfer && isFinanceRole()) {
                  history.push(`/payment/manage/${record._id}`);
                }
              },
            };
          }}
          pagination={{
            defaultPageSize: limit,
            pageSize: limit,
            total: total,
            onChange: onChangePage,
            showSizeChanger: false,
            current: currentPage
          }}
        />
      </Row>

      <NewPostpaidPayment theme='light' />
      <NewPrepaidPayment theme='light' />
      <NewPostpaidPaymentCsv theme='light' />
    </div>
  );
}