import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren } from 'react';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from '../../auth/authSlice';
import { popoverMenuItems } from '../sidebar.config';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { SwitchTheme } from '../../AppHeader/SwitchTheme';
import { canRenderItem } from '../../../utils/checkRoutes';
import { getUserSession } from '../../../utils/userSession';
import { backAccount, setShowModalImpersonate } from '../impersonateSlice';
import { LrvDivider } from '../../../common/components/LrvDivider/LrvDivider';
import { LrvPopover } from '../../../common/components/LrvPopover/LrvPopover';
import { isBusinessManagerRole, isParameteristRole, isSalesManagerRole, isSalesRole, isSuperAdmin, THEME } from '../../../config/commons';

import PopoverItem from './PopoverItem';
import styles from './PopoverMenu.module.scss';

const PopoverContent = (props: { onClickItem?: () => void; pathname: string }) => {
  const { onClickItem, pathname } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { company } = useSelector((state: Store) => state.header);

  const impersonate = localStorage.getItem('impersonate');
  const isAssignedCompany = localStorage.getItem('isAssignedCompany');
  const userSession = getUserSession();

  function renderPopoverMenuItems () {
    const items: JSX.Element[] = [];

    popoverMenuItems.forEach((popoverItem, index) => {
      const hasAccess = popoverItem.rolesRequired && canRenderItem(popoverItem.rolesRequired);
      if ((isSalesManagerRole() || isSalesRole() || isBusinessManagerRole()) && (popoverItem.path === '/analysis-without-stockings' || popoverItem.path === '/archive') && company._id !== userSession.companyId) {
        return <></>;
      }

      if (hasAccess && !(popoverItem.path === '/payments' && isAssignedCompany === 'true')) {
        items.push(
          <PopoverItem
            id={popoverItem.id}
            key={index}
            type='navigation'
            path={popoverItem.path}
            icon={popoverItem.icon}
            title={t(popoverItem.title)}
            isSelected={!!popoverItem.path && pathname.includes(popoverItem.path)}
            onClickItem={onClickItem}
          />
        );
      }
    });

    return items;
  }

  return (
    <div className={styles.popoverContent}>
      {renderPopoverMenuItems()}
      {!isParameteristRole() && <LrvDivider className={styles.popoverDivider} orientation='center' />}
      <PopoverItem
        id='settings_item'
        key='settings'
        type='navigation'
        title={t('sidebar.settings')}
        isSelected={pathname.includes('/settings')}
        icon='settings-2'
        path='/settings'
        onClickItem={onClickItem}
      />
      {isSuperAdmin() || impersonate ?
        impersonate === 'true' ?
          <PopoverItem
            id='back_item'
            key='back'
            type='action'
            title={t('sidebar.backAccount')}
            icon='user-received'
            onClickItem={() => {
              if (onClickItem) {
                onClickItem();
              }
              dispatch(backAccount());
            }}
          />
          :
          <PopoverItem
            id='impersonate_item'
            key='impersonate'
            type='action'
            title={t('sidebar.impersonate')}
            icon='spy'
            onClickItem={() => {
              if (onClickItem) {
                onClickItem();
              }
              dispatch(setShowModalImpersonate(true));
            }}
          />
        : null
      }
      <PopoverItem
        id='theme_item'
        key='theme'
        title={t('users.theme')}
        icon='palette'
        extra={<SwitchTheme />}
      />
      <PopoverItem
        id='submit_logout'
        key='signOut'
        type='action'
        title={t('signIn.signOut')}
        icon='shut-down'
        onClickItem={() => {
          if (onClickItem) {
            onClickItem();
          }
          dispatch(signOut());
        }}
      />
    </div>
  );
};

interface PopoverMenuProps {
  isOpen: boolean;
  onClickItem?: () => void;
  onOpenChange?: (visible: boolean) => void;
  pathname: string;
  placement?: TooltipPlacement | undefined;
}

const PopoverMenu = (props: PropsWithChildren<PopoverMenuProps>) => {
  const { children, isOpen, onClickItem, onOpenChange, pathname, placement } = props;
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  return (
    <LrvPopover
      overlayClassName={cx(styles.popoverMenu, isLightTheme ? styles.popoverLight : styles.popoverDark)}
      content={<PopoverContent pathname={pathname} onClickItem={onClickItem} />}
      onOpenChange={onOpenChange}
      open={isOpen}
      trigger='click'
      placement={placement}
    >
      {children}
    </LrvPopover>
  );
};

export default PopoverMenu;
