import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';
import { capitalize } from 'lodash';

import expandIconSvg from '../../../../assets/expand.svg';
import collapseIconSvg from '../../../../assets/collapse.svg';
import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { ItemReferenceCurves } from '../../../../pages/Units/interfaces';
import { formatter } from '../../../../pages/Sowings/Multiphase/multiphase-helpers';
import { getLabelAxisX, getUnitMeasure } from '../../../../helpers/stocking.helpers';
import * as stockingAnalysisSlice from '../../../../pages/Sowings/Analysis/stockingAnalysisSlice';
import * as geneticsAnalysisSlice from '../../../../pages/Genetics/Analysis/geneticsAnalysisSlice';
import { getMinStage, isConsolidatedAnalysis, getMaxStage } from '../../../../pages/Analysis/helpers';
import * as laboratoryChartSlice from '../../../../pages/Reports/LaboratoryChart/laboratoryChartSlice';
import { Point, Area, DataSource, DataByStage, WeightIncrement } from '../../../../pages/home/interfaces';
import { roundOneDecimal, roundTwoDecimals, stockingPhaseTypes, roundWeight, roundLength, THEME, TICKS_DAYS_RANGE } from '../../../../config/commons';

import './ShadedPlot.scss';
import styles from './ShadedPlot.module.scss';
import { getDayLabel, getWeightIncrement } from './text-between-points.helpers';
import { calcStages, getMaxY, getMinY, fillArea, getFill, getMarginArea, palette, sortY1, sortY2, getParameterLabel, getPoints, typeParam, typeScale, typesChart, getPointX, getPointY, getMinYForPigmentation, getMaxYForPigmentation, renderTickFormat, getNumberTicks, renderTickLeftFormat, GREEN_COLOR, CRIMSON_COLOR, LIME_COLOR, BLUE_COLOR, fillReferenceCurveArea, getMeanValueY, isReferenceCurveMetric, DARK_RED_COLOR, getLineData, filterPointByStage, filterPointConsolidated, filterPointNotConsolidated, getStockingChartLeftPosition, getMaturationChartLeftPosition, renderTickFormatForMaturations } from './helpers';


let zoomDone = false;
let idleTimeout: NodeJS.Timeout | null;
let currentStageActive: string;
let currentStageConsolidatedActive: string;
let numbersTicks: number;

const DEFAULT_CIRCLE = 8; // For example, maturation and laboratory curves
const CONSOLIDATED_CIRCLE = 11;
const OTHER_CIRCLE = 9;
const CONSOLIDATED_CIRCLE_ACTIVE = CONSOLIDATED_CIRCLE + 3;
const OTHER_CIRCLE_ACTIVE = OTHER_CIRCLE + 3;
const DEFAULT_CIRCLE_ACTIVE = DEFAULT_CIRCLE + 3;
const TIME_TRANSITION = 300;
const TICK_PADDING = 8;

interface Props {
  colorFillRect?: string;
  colorLine: string;
  colorsPoints?: string[];
  colorsStroke?: string[];
  companyData: Company;
  container: HTMLDivElement | null;
  dataMetric: ItemReferenceCurves[];
  dataSource?: DataSource[];
  firstStage: number;
  height: number;
  lastStage: number;
  parameter: string;
  phaseType: string;
  scale?: string;
  showLabels?: boolean;
  typeChart: string;
  typeMetric?: string;
  width: number;
  movingAverage?: number;
  showMovingAverage?: boolean;
  selectedTickStoke?: string;
  isMaturationCurves?: boolean;
  dispatch?: Dispatch<GenericParam>;
}

class D3ShadedPlot {
  container: HTMLDivElement | null;
  colorsPoints: string[];
  colorsStroke: string[];
  svg: d3.Selection<SVGSVGElement, Point, null, undefined>;
  groupMain: d3.Selection<SVGGElement, Point, null, undefined>;

  pathArea: d3.Selection<SVGPathElement, Point, null, undefined> = d3.select<SVGPathElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  areaData: d3.Area<Area> = d3.area();
  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  area: Area[] = [];

  margin = { top: 24, right: 24, bottom: 20, left: 50 };
  yAxis: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  scale: string;
  typeMetric?: string;
  parameter: string;
  chart: string;

  dataMetric: ItemReferenceCurves[] = [];
  dataY1: number[] = [];
  dataY2: number[] = [];
  dataSource: DataSource[] = [];
  allPoints: Point[] = [];

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  fill: string = palette.GLOBAL;
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  firstStageDefault = 0;
  lastStageDefault = 0;
  shouldShowAllPoints = true;
  companyId = '';
  companyData: Company;

  containerLeft: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerTop: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerRight: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerBottom: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  colorFillRect = '';
  colorLine = '';

  phaseType: string;
  showLabels: boolean;

  movingAverage?: number;
  showMovingAverage?: boolean;
  selectedTickStoke: string;

  isMaturationCurves?: boolean;

  clip: d3.Selection<d3.BaseType, Point, null, undefined> = d3.select<d3.BaseType, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<Point> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  dispatch?: Dispatch<GenericParam>;

  // eslint-disable-next-line
  constructor(props: Props) {
    const { colorFillRect = '', colorLine, colorsPoints = [], colorsStroke = [], companyData, container, dataMetric, dataSource = [], firstStage, height, lastStage, parameter, phaseType, scale = typeScale.LINEAR, showLabels = false, typeChart, typeMetric, width, movingAverage = 2, showMovingAverage = true, selectedTickStoke = 'white', isMaturationCurves = false, dispatch } = props;

    this.colorsPoints = colorsPoints;
    this.colorsStroke = colorsStroke;
    this.container = container;
    this.typeMetric = typeMetric;
    this.scale = scale;
    this.parameter = parameter;
    this.dataMetric = dataMetric;
    this.dataSource = dataSource;
    this.chart = typeChart;
    this.companyId = companyData._id;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.selectedTickStoke = selectedTickStoke;
    this.shouldShowAllPoints = dataSource.length === 1 && typeChart === typesChart.STOCKINGS;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.phaseType = phaseType;
    this.showLabels = showLabels;

    this.movingAverage = movingAverage;
    this.showMovingAverage = showMovingAverage;
    this.isMaturationCurves = isMaturationCurves;

    this.dispatch = dispatch;

    this.firstStageDefault = getMinStage(phaseType);
    this.lastStageDefault = getMaxStage(companyData, phaseType);
    this.companyData = companyData;

    d3.select(container).select('#tooltipFactorK').remove();
    d3.select(container).select('#tooltipGrowthDelta').remove();
    d3.select(container).select('#tooltipComparisionChart').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select<HTMLDivElement | null, Point>(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    if (firstStage === lastStage && firstStage === 0) {
      const minStage = getMinStage(phaseType);
      const stages = calcStages(dataSource, minStage);
      this.firstStage = stages[0];
      this.lastStage = stages[1];
    } else {
      this.firstStage = firstStage;
      this.lastStage = lastStage;
    }

    zoomDone = true;

    this.sortData(this.dataMetric);
    this.fill = getFill({ dataSource: this.typeMetric, isMaturationCurves: this.isMaturationCurves });

    this.createBrushElement();

    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
    this.renderMovingAverageLine();
  }

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width + ((DEFAULT_CIRCLE_ACTIVE) * 2))
      .attr('height', height)
      .attr('x', - DEFAULT_CIRCLE_ACTIVE)
      .attr('y', DEFAULT_CIRCLE_ACTIVE);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush<Point>()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStageDefault, lastStageDefault } = this;

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }

      zoomDone = false;
      const { maxY, minY } = this.getYDomainData();

      this.resetBrush({ x0: firstStageDefault, y0: minY, x1: lastStageDefault, y1: maxY });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;
    const extentX0 = this.x.invert(x0);
    const extentX1 = this.x.invert(x1);

    if (this.dispatch) {
      switch (this.chart) {
        case typesChart.STOCKINGS:
          this.dispatch(stockingAnalysisSlice.setFirstStageZoom(extentX0));
          this.dispatch(stockingAnalysisSlice.setLastStageZoom(extentX1));
          break;

        case typesChart.LABORATORY:
          this.dispatch(laboratoryChartSlice.setFirstStageZoom(extentX0));
          this.dispatch(laboratoryChartSlice.setLastStageZoom(extentX1));
          break;

        case typesChart.MATURATIONS:
          this.dispatch(geneticsAnalysisSlice.setFirstStageZoom(extentX0));
          this.dispatch(geneticsAnalysisSlice.setLastStageZoom(extentX1));
          break;
      }
    }

    this.x.domain([x0, x1].map(this.x.invert));
    this.y.domain([y1, y0].map(this.y.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { y0, y1 } = props;
    const { phaseType, chart } = this;
    let x0 = props.x0;
    let x1 = props.x1;

    if ((chart === typesChart.MATURATIONS && phaseType !== stockingPhaseTypes.LARVAE) || chart === typesChart.LABORATORY) {
      // calculate rangId for x0 and x1 (x1 is a number inside of a range [a, b])
      x0 = x0 + 1;
      x1 = Math.floor(x1 / TICKS_DAYS_RANGE) + 1;
    }

    this.x.domain([x0, x1]);
    this.y.domain([y0, y1]);

    if (this.dispatch) {
      switch (this.chart) {
        case typesChart.STOCKINGS:
          this.dispatch(stockingAnalysisSlice.setFirstStageZoom(x0));
          this.dispatch(stockingAnalysisSlice.setLastStageZoom(x1));
          break;

        case typesChart.LABORATORY:
          this.dispatch(laboratoryChartSlice.setFirstStageZoom(x0));
          this.dispatch(laboratoryChartSlice.setLastStageZoom(x1));
          break;

        case typesChart.MATURATIONS:
          this.dispatch(geneticsAnalysisSlice.setFirstStageZoom(x0));
          this.dispatch(geneticsAnalysisSlice.setLastStageZoom(x1));
          break;
      }
    }

    this.refreshBrush();
    this.hideTooltipOption();
  }

  refreshBrush = () => {
    const { parameter, phaseType, height, chart } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((x) => (chart === typesChart.MATURATIONS || chart === typesChart.LABORATORY) ? renderTickFormatForMaturations(x, phaseType) : renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    const { line } = this.generateLines();

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.gClipPath
      .selectAll('.pathLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', line as any);

    const movingAverageLine = this.getMovingAverageLine();
    this.gClipPath
      .select('#movingAverageLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', movingAverageLine as any);

    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.x(point.x))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.y(point.y));

    const areaData = this.areaData(this.area);
    this.pathArea
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', areaData);

    this.renderExpandBrush();
    this.renderResetBrush();
  }

  fillAreaChart = () => {
    const { typeMetric, dataMetric, phaseType } = this;

    if (typeMetric && isReferenceCurveMetric(typeMetric)) {
      const stages = dataMetric.map((data) => data.stage);
      return fillReferenceCurveArea({ dataY1: this.dataY1, dataY2: this.dataY2, stages });
    }

    const minStage = getMinStage(phaseType);
    return fillArea(this.dataY1, this.dataY2, minStage);
  };

  renderLineSelected () {
    const { container, groupMain, height, selectedTickStoke } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke)
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipComparisionChart')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentStageActive).attr('r', this.shouldShowAllPoints ? OTHER_CIRCLE_ACTIVE : DEFAULT_CIRCLE_ACTIVE);
        d3.selectAll(currentStageConsolidatedActive).attr('r', this.shouldShowAllPoints ? CONSOLIDATED_CIRCLE_ACTIVE : CONSOLIDATED_CIRCLE);
      });
  }

  updateDataPoints = () => {
    const { fill } = this;

    this.renderTriangle();
    this.renderExpandBrush();
    this.renderResetBrush();
    this.renderLinesAxis();
    this.area = this.fillAreaChart();
    this.buildAxisX();
    this.buildAxisY();

    this.createTooltip();
    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();
    this.createArea();

    const areaData = this.areaData(this.area);
    this.pathArea = this.groupMain
      .append('path')
      .attr('d', areaData)
      .style('fill', fill);

    this.renderLineSelected();
    this.hideBorders();
    this.createGClipPathElement();
    this.renderLines();

    this.drawXAxis();
    this.drawYAxis();
    this.renderPoints();
    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  };

  hideBorders () {
    const { container, groupMain, width, height, margin } = this;

    const hideBorder = groupMain.append('g')
      .attr('id', 'hideBorderContainer');

    d3.select(container).selectAll('.hideBorder').remove();

    //container top
    this.containerTop = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', width + margin.left + margin.right)
      .attr('height', margin.top)
      .style('fill', this.colorFillRect);

    // container bottom
    this.containerBottom = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', height)
      .attr('width', width + margin.left + margin.right)
      .attr('height', margin.bottom)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', 0)
      .attr('width', margin.left)
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', width + 1)
      .attr('y', 0)
      .attr('width', margin.right)
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);
  }

  updateBorders () {
    this.containerTop
      .style('fill', this.colorFillRect);

    // container bottom
    this.containerBottom
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight
      .style('fill', this.colorFillRect);
  }

  renderTooltips () {
    const { dataSource, x, y, tooltip,
      chart, parameter, shouldShowAllPoints,
      firstStage, lastStage, allPoints,
      width, height, phaseType,
      renderTooltipsForStockings, renderTooltipsForMaturations,
      colorsPoints,
      groupMain,
      dispatch,
    } = this;

    const bisect = d3.bisector(function (point: Point) {
      return point.x;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentStageActive).attr('r', shouldShowAllPoints ? OTHER_CIRCLE : DEFAULT_CIRCLE);
        d3.selectAll(currentStageConsolidatedActive).attr('r', shouldShowAllPoints ? CONSOLIDATED_CIRCLE : DEFAULT_CIRCLE);
      })
      .on('mousemove', function (event) {
        const amountEnabled = dataSource.filter((data) => {
          if (data.enabled && data.avgPoint) {
            return data.avgPoint.length > 0;
          }
          return false;
        }).length;

        if (amountEnabled === 0) {
          return;
        }

        let x0 = x.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);
        
        const isXVisible = (x0 >= x.domain()[0]) && (x0 <= x.domain()[1]);
        if (!isXVisible) {
          return;
        }
        
        const index = bisect(allPoints, x0, 1);

        const previousPoint = allPoints[index - 1];
        const currentPoint = allPoints[index];
        let selectedPoint: Point;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        const weightIncrement = getWeightIncrement({ selectedPoint, dataSource });

        if (!(selectedPoint.x < firstStage || selectedPoint.x > lastStage)) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        const dataByStage: DataByStage[] = [];
        const pointsList: Point[] = [];

        for (let index = 0; index < dataSource.length; index++) {
          const data = dataSource[index];
          if (!data.enabled) {
            continue;
          }

          const points: Point[] | undefined = getPoints(data, chart);
          if (!points || points.length === 0) {
            continue;
          }

          const pointsByStage = filterPointByStage(points, selectedPoint.x);
          const pointsConsolidated = filterPointConsolidated(pointsByStage);
          const pointsNotConsolidated = filterPointNotConsolidated(pointsByStage);

          pointsConsolidated.forEach((point: Point) => {
            const _dataByStage: DataByStage = { point, name: data.name, index, density: data.density };
            dataByStage.push(_dataByStage);
            pointsList.push(point);
          });

          pointsNotConsolidated.forEach((point: Point) => {
            const _dataByStage: DataByStage = { point, name: data.name, index, density: data.density };
            dataByStage.push(_dataByStage);
            pointsList.push(point);
          });
        }

        d3.selectAll('.points circle').attr('r', () => shouldShowAllPoints ? OTHER_CIRCLE : DEFAULT_CIRCLE);
        d3.selectAll('.points .consolidated').attr('r', CONSOLIDATED_CIRCLE);

        if (pointsList.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: Point = pointsList.reduce(function (prev: Point, current: Point) {
          return (prev.y > current.y) ? prev : current;
        });
        const lowestValue: Point = pointsList.reduce(function (prev: Point, current: Point) {
          return (prev.y < current.y) ? prev : current;
        });

        const marginLeft = x(higherValue.x);
        const marginBottom = y(lowestValue.y);
        currentStageActive = `.stage${higherValue.x}`;
        currentStageConsolidatedActive = `.stage-consolidated${higherValue.x}`;

        d3.selectAll(currentStageActive).attr('r', () => shouldShowAllPoints ? OTHER_CIRCLE_ACTIVE : DEFAULT_CIRCLE_ACTIVE);
        d3.selectAll(currentStageConsolidatedActive).attr('r', shouldShowAllPoints ? CONSOLIDATED_CIRCLE_ACTIVE : CONSOLIDATED_CIRCLE);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidthDefault = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidthDefault < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', () => {
            let value = 0;
            const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

            if ((marginLeft + tooltipTotalWidth) < width) {
              value = -16; // has to be the same that width, but negative
            } else {
              value = tooltipTotalWidth;
            }

            return `${value}px`;
          });

        tooltip
          .style('left', () => {
            let value = '0px';
            switch (chart) {
              case typesChart.STOCKINGS:
                value = getStockingChartLeftPosition(leftPositionProps);
                break;
              case typesChart.LABORATORY:
              case typesChart.MATURATIONS:
                value = getMaturationChartLeftPosition(leftPositionProps);
                break;
            }
            return value;
          })
          .style('bottom', () => {
            let value = 0;
            const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;

            if (marginBottom + tooltipTotalHeight > height) {
              value = (height - marginBottom) + (tooltipTotalHeight / 4);
            } else {
              value = (height - marginBottom) - (tooltipTotalHeight / 4);
            }
            return `${value}px`;
          });

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();

        switch (chart) {
          case typesChart.STOCKINGS:
            renderTooltipsForStockings({ dataByStage, tooltipContent, shouldShowAllPoints, colorsPoints, phaseType, parameter, weightIncrement });
            break;

          case typesChart.LABORATORY:
          case typesChart.MATURATIONS:
            renderTooltipsForMaturations(dataByStage, tooltipContent, parameter, colorsPoints, phaseType, dispatch);
            break;
        }
      });
  }

  renderTooltipsForStockings (props: { dataByStage: DataByStage[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>, shouldShowAllPoints: boolean, colorsPoints: string[], phaseType: string, parameter: string, weightIncrement?: WeightIncrement }) {
    const { dataByStage, tooltipContent, shouldShowAllPoints, colorsPoints, phaseType, parameter, weightIncrement } = props;
    for (let index = 0; index < dataByStage.length; index++) {
      const data = dataByStage[index];
      
      if (parameter === typeParam.AVG_WEIGHT && weightIncrement && index === 0) {
        const value = `${roundWeight({ value: weightIncrement.diffAverageWeight })} ${getDayLabel({ diffStage: weightIncrement.diffStage })}`;

        tooltipContent.append('div')
          .attr('class', styles.weightIncrementTitle)
          .style('color', weightIncrement.diffAverageWeight > 0 ? 'royalblue' : 'orangered')
          .html(`${i18next.t('shadedplot.increase')}: <strong>${value}</strong>`);

        tooltipContent.append('hr');
      }

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryTitle = entry.append('div')
        .attr('class', styles.entryHeader);

      entryTitle.append('a')
        .attr('class', styles.entryTitle)
        .style('color', shouldShowAllPoints ? 'royalblue' : colorsPoints[data.index])
        .attr('href', `/production/analysis/${data.point._id}`)
        .attr('target', '_blank')
        .html(data.point.code);

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      let pigmentationLabel = i18next.t('shadedplot.type.pigmentation');
      pigmentationLabel = pigmentationLabel.toLowerCase();
      pigmentationLabel = pigmentationLabel.charAt(0).toUpperCase() + pigmentationLabel.slice(1);

      if (phaseType === stockingPhaseTypes.LARVAE) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.inputData.stage')}: <strong>${formatter(phaseType, data.point.inputData.stage)}</strong>`);
      } else {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${getLabelAxisX(phaseType)}: <strong>${formatter(phaseType, data.point.inputData.stage)}</strong>`);
      }

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${roundWeight({ value: data.point.resultData.averageWeight })}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.averageLength')}: <strong>${roundLength({ value: data.point.resultData.averageLength })}</strong>`);

      if (phaseType === stockingPhaseTypes.LARVAE) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.larvaePerGram')}: <strong>${data.point.resultData.larvaePerGram}</strong>`);
      }

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${capitalize(i18next.t('stockings.pdf.typeParameter.uniformity').toLowerCase())}: <strong>${data.point.resultData.uniformity} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.variationCoefficientLength')}: <strong>${data.point.resultData.variationCoefficientLength} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${pigmentationLabel}: <strong>${data.point.resultData.pigmentation}</strong>`);

      if (data.point.resultData.animalsAboveConditionFactor) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('shadedplot.type.factorK')}: <strong>${data.point.resultData.animalsAboveConditionFactor} %</strong>`);
      }

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.pdf.density')} <strong>${applyThousandsSeparator(data.density)}</strong>`);

      if (index !== dataByStage.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  renderTooltipsForMaturations (dataByStage: DataByStage[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>, parameter: string, colorsPoints: string[], phaseType: string, dispatch?: Dispatch<GenericParam>) {
    for (let index = 0; index < dataByStage.length; index++) {
      const name = dataByStage[index].name;
      const indexColor = dataByStage[index].index;
      const point = dataByStage[index].point;
      const value = Math.round(point.y * 100) / 100;
      const label: string = getParameterLabel(parameter);
      const stageValue = phaseType === stockingPhaseTypes.LARVAE ? point.x : point.stageRange;

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryTitle = entry.append('div')
        .attr('class', styles.entryHeader);

      entryTitle.append('a')
        .attr('class', styles.entryTitle)
        .style('color', colorsPoints[indexColor] ?? 'royalblue')
        .html(name)
        .on('click', () => {
          if (dispatch) {
            dispatch(geneticsAnalysisSlice.setStage(point.x));
            dispatch(geneticsAnalysisSlice.setMaturationName(name));
            dispatch(geneticsAnalysisSlice.setShowStockingsModal(true));
          }
        });

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${label}: <strong>${getMeanValueY({ parameter, mean: value, forceCast: phaseType !== stockingPhaseTypes.LARVAE })} ${getUnitMeasure({ parameter, phaseType })}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.analysis')}: <strong>${point.totalN}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${getLabelAxisX(phaseType, typesChart.MATURATIONS)}: <strong>${stageValue || ''}</strong>`);

      if (index !== dataByStage.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  getCurveType () {
    const { shouldShowAllPoints, phaseType, chart } = this;

    if (chart === typesChart.MATURATIONS || chart === typesChart.LABORATORY) {
      return d3.curveBasis;
    }

    if (!shouldShowAllPoints) {
      return d3.curveMonotoneX;
    }

    if (phaseType === stockingPhaseTypes.LARVAE) {
      return d3.curveBasis;
    }

    return d3.curveCatmullRom;
  }

  getLineData (avgPoint: Point[]) {
    const lineData: { [key: number]: Point[] } = {};

    for (let i = 0; i < avgPoint.length; ++i) {
      const point = avgPoint[i];
      const index = point.x;

      if (!lineData[index]) {
        lineData[index] = [];
      }

      lineData[index].push(point);
    }

    return lineData;
  }

  generateLines () {
    const { x, y, dataSource } = this;

    const linePoints: Point[][] = [];
    const indexes: number[] = [];

    const line = d3.line<Point>()
      .x((d) => { return x(d.x); })
      .y((d) => { return y(d.y); })
      .curve(this.getCurveType());

    if (this.firstStage === this.lastStage) {
      return {
        indexes,
        linePoints,
        line,
      };
    }

    for (let index = 0; index < dataSource.length; index++) {
      const avgPoint = dataSource[index].avgPoint;
      const isEnabled = dataSource[index].enabled;

      if (!isEnabled) {
        continue;
      }

      const lineData = this.getLineData(avgPoint);
      const linePoint: Point[] = getLineData(lineData);
      linePoints.push(linePoint);
      indexes.push(index);
    }

    return {
      indexes,
      linePoints,
      line,
    };
  }

  renderLines () {
    const { container, shouldShowAllPoints, colorsPoints, gClipPath, brush } = this;

    const { linePoints, line, indexes } = this.generateLines();
    d3.select(container).selectAll('.brush').remove();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    for (let index = 0; index < linePoints.length; index++) {
      const linePoint = linePoints[index];
      gClipPath
        .append('path')
        .datum(linePoint)
        .attr('class', cx('pathLine', shouldShowAllPoints ? 'line' : 'lines'))
        .attr('stroke', shouldShowAllPoints ? this.colorLine : colorsPoints[indexes[index]])
        .attr('d', line);
    }
  }

  getParameterValue (avgPoint: Point) {
    const { parameter } = this;

    if (parameter === typeParam.PIGMENTATION) {
      return roundTwoDecimals(avgPoint.y);
    }

    if (parameter === typeParam.AVG_WEIGHT) {
      return roundWeight({ value: avgPoint.y, showUnit: false });
    }

    if (parameter === typeParam.CV_LENGTH) {
      return roundLength({ value: avgPoint.y, showUnit: false });
    }

    return roundOneDecimal(avgPoint.y);
  }

  renderLabelPoints (props: { value: string; avgPoint: Point; marginX?: number; marginY?: number }) {
    const { value, avgPoint, marginX = 0, marginY = 0 } = props;
    const { groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    groupMain.append('text')
      .attr('class', cx('labelsPoints', isLightTheme ? 'labelsPointsLight' : 'labelsPointsDark'))
      .attr('x', this.x(avgPoint.x) + marginX)
      .attr('y', this.y(avgPoint.y) + marginY)
      .style('text-anchor', 'middle')
      .text(value);
  }

  renderLabelsOfPoints () {
    const { dataSource } = this;

    for (let i = 0; i < dataSource.length; i++) {
      const points = dataSource[i].points;
      const avgPoints = dataSource[i].avgPoint;
      const isEnabled = dataSource[i].enabled;

      if (!isEnabled) {
        continue;
      }

      for (let j = 0; j < avgPoints.length; j++) {
        const avgPoint = avgPoints[j];
        const stages = points ? points.filter((point: Point) => point.x === avgPoint.x) : [];
        const value = `${this.getParameterValue(avgPoint)}`;

        const marginX = j === avgPoints.length - 1 ? -26 : 26;
        const marginY = stages.length === 1 ? - 12 : 0;

        this.renderLabelPoints({ value, avgPoint, marginX, marginY });
      }
    }
  }

  getDefaultColor (point: Point) {
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    const weightOutOfRangeColor = '#f79e13';

    if (point.unusualAverageWeight) {
      return weightOutOfRangeColor;
    }

    return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : isLightTheme ? '#a8b7ec' : '#d9d9d9';
  }

  fillPoints (point: Point, index: number) {
    const { shouldShowAllPoints, parameter, colorsPoints } = this;
    const defaultFillColor = this.getDefaultColor(point);

    if (!shouldShowAllPoints) {
      return colorsPoints[index];
    }

    if (parameter === typeParam.PIGMENTATION) {
      return defaultFillColor;
    }

    const selectArea = this.area.find((area) => area.x === point.x);

    if (!selectArea) {
      return defaultFillColor;
    }

    if (parameter === typeParam.UNIFORMITY || parameter === typeParam.AVG_WEIGHT || parameter === typeParam.AVG_LENGTH) {
      if (point.y > selectArea.high && !point?.unusualAverageWeight) {
        return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : GREEN_COLOR;
      }
      if (point.y < selectArea.low && !point?.unusualAverageWeight) {
        return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : CRIMSON_COLOR;
      }
      return defaultFillColor;
    }

    if (point.y > selectArea.high && !point?.unusualAverageWeight) {
      return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : CRIMSON_COLOR;
    }
    if (point.y < selectArea.low && !point?.unusualAverageWeight) {
      return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : GREEN_COLOR;
    }

    return defaultFillColor;
  }

  strokePoint (point: Point, index: number) {
    const { shouldShowAllPoints, parameter, colorsStroke } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    const defaultStrokeColor = isConsolidatedAnalysis(point.type) ? BLUE_COLOR : isLightTheme ? '#ffffff' : '#131B55';

    if (!shouldShowAllPoints) {
      return colorsStroke[index];
    }

    if (parameter === typeParam.PIGMENTATION) {
      return defaultStrokeColor;
    }

    const x = point.x === 0 ? point.x : point.x - 1;
    const selectArea = this.area[x];

    if (!selectArea) {
      return defaultStrokeColor;
    }

    if (parameter === typeParam.UNIFORMITY || parameter === typeParam.AVG_WEIGHT || parameter === typeParam.AVG_LENGTH) {
      if (point.y > selectArea.high) {
        return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : LIME_COLOR;
      }
      if (point.y < selectArea.low) {
        return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : DARK_RED_COLOR;
      }
      return defaultStrokeColor;
    }

    if (point.y > selectArea.high) {
      return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : DARK_RED_COLOR;
    }
    if (point.y < selectArea.low) {
      return isConsolidatedAnalysis(point.type) ? BLUE_COLOR : LIME_COLOR;
    }
    return defaultStrokeColor;
  }

  generatePoints () {
    const { dataSource } = this;

    const avgPoints: Point[][] = [];
    const points: Point[][] = [];
    const indexes: number[] = [];

    for (let index = 0; index < dataSource.length; index++) {
      const point = dataSource[index].points;
      const avgPoint = dataSource[index].avgPoint;
      const isEnabled = dataSource[index].enabled;

      if (!isEnabled || !point) {
        continue;
      }

      points.push(point);
      avgPoints.push(avgPoint);
      indexes.push(index);
    }

    return {
      avgPoints,
      points,
      indexes,
    };
  }

  renderPoints () {
    const { x, y, shouldShowAllPoints, gClipPath } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    const { avgPoints, points, indexes } = this.generatePoints();

    for (let index = 0; index < avgPoints.length; index++) {
      const point = points[index];
      const avgPoint = avgPoints[index];

      gClipPath.append('g')
        .attr('class', 'points')
        .attr('fill', 'transparent')
        .selectAll('circle')
        .data(shouldShowAllPoints ? point : avgPoint)
        .enter()
        .append('circle')
        .attr('id', (point: Point) => `point_${point.code}`)
        .attr('class', (point: Point) => {
          if (this.firstStage <= point.x && point.x <= this.lastStage) {
            if (this.shouldShowAllPoints) {
              return cx(styles.circle, isConsolidatedAnalysis(point.type) ? `stage-consolidated${point.x} consolidated` : `stage${point.x}`);
            }
            return cx(styles.circle, isConsolidatedAnalysis(point.type) ? `stage-consolidated${point.x}` : `stage${point.x}`);
          }
          return styles.hideCircle;
        })
        .attr('r', (point: Point) => {
          if (this.shouldShowAllPoints) {
            return isConsolidatedAnalysis(point.type) ? CONSOLIDATED_CIRCLE : OTHER_CIRCLE;
          }
          return DEFAULT_CIRCLE;
        })
        .attr('cx', (point: Point) => x(point.x))
        .attr('cy', (point: Point) => y(point.y))
        .attr('fill', (point: Point) => this.fillPoints(point, indexes[index]))
        .attr('stroke', isLightTheme ? '#ffffff' : '#131B55')
        .attr('stroke-width', 2)
        .on('click', (event, point: Point) => this.onClickCircle(point));
    }
  }

  onClickCircle = (point: Point) => {
    if (this.chart === typesChart.MATURATIONS || this.chart === typesChart.LABORATORY) {
      return;
    }
    const url = `/production/analysis/${point._id}`;
    window.open(url, '_blank');
  };

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', 'lineAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', -8)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderTriangle = () => {
    const { container, margin } = this;

    const triangleMargins = {
      left: margin.left - 5,
    };

    d3.select(container).selectAll('.triangleComparisionChart').remove();
    d3.select(container).selectAll('.triangleGrowthDelta').remove();
    d3.select(container).selectAll('.triangleLeftAxisY').remove();
    d3.select(container).selectAll('.triangleRightAxisY').remove();

    d3.select(container)
      .attr('id', 'triangleComparisionChart')
      .append('div')
      .attr('class', cx('triangleComparisionChart', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);
  };

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0)
      .on('end', () => { // 'end' means when finish transition
        this.tooltipOption
          .style('visibility', 'hidden')
          .style('pointer-events', 'none');
      });
  };

  showTooltipOption = (marginRight: number, label: string) => {
    this.tooltipOption
      .style('visibility', 'visible')
      .style('pointer-events', 'auto');

    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(label)
      .style('right', (marginRight + 28) + 'px')
      .style('top', '-8px');
  };

  renderExpandBrush = () => {
    const { margin, width, svg, colorLine, dispatch } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#expandBrushButton').remove();
    svg.select('#defsBackgroundIcon').remove();

    if (!dispatch) {
      return null;
    }

    svg
      .append('image')
      .attr('id', 'expandBrushButton')
      .attr('x', marginLeft - 5)
      .attr('y', -10)
      .attr('xlink:href', expandIconSvg)
      .attr('width', 28)
      .attr('height', 28)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right, i18next.t('shadedplot.expand')))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        zoomDone = false;
        const { firstStageDefault, lastStageDefault } = this;

        const { maxY, minY } = this.getYDomainData(firstStageDefault, lastStageDefault);
        this.resetBrush({ x0: firstStageDefault, y0: minY, x1: lastStageDefault, y1: maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', colorLine)
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  renderResetBrush = () => {
    const { margin, width, svg, colorLine, dispatch } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#resetBrushButton').remove();
    svg.select('#defsBackgroundIcon').remove();

    if (!dispatch) {
      return null;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft - 41)
      .attr('y', -10)
      .attr('xlink:href', collapseIconSvg)
      .attr('width', 28)
      .attr('height', 28)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right + 36, i18next.t('shadedplot.reset')))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        zoomDone = false;
        const { chart, phaseType } = this;
        let firstStage = this.firstStage;
        let lastStage = this.lastStage;

        const { maxY, minY } = this.getYDomainData(this.firstStage, this.lastStage);

        if ((chart === typesChart.LABORATORY || chart === typesChart.MATURATIONS) && phaseType !== stockingPhaseTypes.LARVAE) {
          firstStage = firstStage - 1;
          lastStage = (lastStage * TICKS_DAYS_RANGE) - 1;
        }

        this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', colorLine)
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  updateAxis () {
    const { height, phaseType, parameter, firstStage, lastStage, typeMetric, dataMetric, chart } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.renderTriangle();
    this.renderExpandBrush();
    this.renderResetBrush();
    this.renderLinesAxis();

    this.buildAxisX();
    this.buildAxisY();

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage, typeMetric, dataMetric });
    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((x) => (chart === typesChart.MATURATIONS || chart === typesChart.LABORATORY) ? renderTickFormatForMaturations(x, phaseType) : renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    this.createArea();
  }

  showOrHideFirstLineOfAxisXTick () {
    const tickG = d3.selectAll<HTMLElement, undefined>('#axisX>.tick').nodes()[0];
    const transformProperty: string = tickG?.getAttribute('transform') || '';
    const match = transformProperty.match(/translate\(([\d.]+),[\d.]+\)/);

    if (match) {
      const xPosition = parseFloat(match[1]);
      if (xPosition === 0) {
        d3.select(tickG).select('line').style('display', 'none');
      } else {
        d3.select(tickG).select('line').style('display', 'flex');
      }
    } else {
      d3.select(tickG).select('line').style('display', 'flex');
    }
  }

  createArea () {
    const { x, y } = this;

    this.areaData = d3.area<Area>()
      .curve(d3.curveCatmullRom)
      .x(function (d) {
        return x(d.x);
      })
      .y0(function (d) {
        return y(d.low);
      })
      .y1(function (d) {
        return y(d.high);
      });
  }

  updateData () {
    const { pathArea, fill, parameter } = this;

    this.area = this.fillAreaChart();
    this.updateAxis();
    const areaData = this.areaData(this.area);

    pathArea.transition()
      .duration(TIME_TRANSITION)
      .style('fill', fill)
      .attr('d', parameter === typeParam.PIGMENTATION ? '' : areaData);

    this.createGClipPathElement();
    this.renderLines();
    this.updateBorders();

    this.renderPoints();
    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  }

  refreshChart (props: { dataMetric: ItemReferenceCurves[]; dataSource?: DataSource[]; firstStage: number; lastStage: number; width: number; height: number; phaseType: string; colorsPoints?: string[]; colorsStroke?: string[]; parameter: string; typeMetric?: string; scale?: string; movingAverage?: number; showMovingAverage?: boolean; colorFillRect: string; isMaturationCurves?: boolean; colorLine: string; selectedTickStoke: string; }) {
    const { dataMetric, dataSource = [], firstStage, lastStage, width, height, phaseType, colorsPoints = [], colorsStroke = [], parameter, typeMetric, scale = typeScale.LINEAR, movingAverage = 2, showMovingAverage = true, colorFillRect, isMaturationCurves, colorLine, selectedTickStoke } = props;
    const { tooltip, container, companyData } = this;

    if (this.parameter !== parameter) {
      zoomDone = true;
    }

    if (this.phaseType !== phaseType) {
      this.firstStageDefault = getMinStage(phaseType);
      this.lastStageDefault = getMaxStage(companyData, phaseType);
    }

    this.parameter = parameter;
    this.typeMetric = typeMetric;
    this.scale = scale;
    this.colorsPoints = colorsPoints;
    this.colorsStroke = colorsStroke;
    this.dataSource = dataSource;
    this.dataMetric = dataMetric;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.phaseType = phaseType;
    this.movingAverage = movingAverage;
    this.showMovingAverage = showMovingAverage;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.selectedTickStoke = selectedTickStoke;
    this.shouldShowAllPoints = dataSource.length === 1 && this.chart === typesChart.STOCKINGS;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();
    d3.select(container).select('#movingAverageLine').remove();

    d3.select(container).selectAll('.labelsPoints').remove();
    d3.select(container).selectAll('.pathLine').remove();
    d3.select(container).selectAll('.points').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);
    this.sortData(dataMetric);
    this.fill = getFill({ dataSource: this.typeMetric, isMaturationCurves });
    this.renderLineSelected();
    this.updateData();
    this.renderTooltipOption();
    this.renderTooltips();
    this.renderMovingAverageLine();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    const minX = firstStage;
    const maxX = lastStage;

    this.x = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  }

  buildAxisY () {
    const { scale, height } = this;
    const { maxY, minY } = this.getYDomainData();

    if (scale === typeScale.LOGARITHMIC) {
      this.y = d3.scaleSymlog()
        .domain([minY, maxY])
        .range([height, 0]);
    } else {
      this.y = d3.scaleLinear()
        .domain([minY, maxY])
        .range([height, 0]);
    }
  }

  getYDomainData = (firstStageValue?: number, lastStageValue?: number) => {
    const { chart, parameter, dataSource, area } = this;
    const margin = getMarginArea(parameter);

    const firstStage = firstStageValue ?? zoomDone ? this.firstStage : this.firstStageDefault;
    const lastStage = lastStageValue ?? zoomDone ? this.lastStage : this.lastStageDefault;

    let minY = parameter === typeParam.PIGMENTATION ? getMinYForPigmentation(dataSource, firstStage, lastStage) : getMinY(dataSource, area, firstStage, lastStage, chart);
    let maxY = parameter === typeParam.PIGMENTATION ? getMaxYForPigmentation(dataSource, firstStage, lastStage) : getMaxY(dataSource, area, firstStage, lastStage, chart);

    minY = minY - (minY * margin) < 0 ? 0 : minY - (minY * margin);
    maxY = maxY + (maxY * margin);

    return {
      maxY,
      minY,
    };
  }

  drawXAxis () {
    const { height, groupMain, phaseType, firstStage, lastStage, typeMetric, dataMetric, chart } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage, typeMetric, dataMetric });

    const axis = d3.axisBottom(this.x)
      .tickFormat((x) => (chart === typesChart.MATURATIONS || chart === typesChart.LABORATORY) ? renderTickFormatForMaturations(x, phaseType) : renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();
  }

  drawYAxis () {
    const { groupMain, phaseType, parameter } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  sortData (dataMetric: ItemReferenceCurves[]) {
    const { parameter, typeMetric } = this;

    this.sortDataSource();
    this.sortAllPoints();
    this.dataY1 = sortY1({ data: dataMetric, parameter, typeMetric });
    this.dataY2 = sortY2({ data: dataMetric, parameter, typeMetric });
  }

  sortDataSource () {
    const { dataSource, parameter, chart } = this;

    for (let i = 0; i < dataSource.length; i++) {
      const points = getPoints(dataSource[i], chart);
      const avgPoints: Point[] = dataSource[i].avgPoint;

      const pointsList: Point[] = [];
      const avgPointsList: Point[] = [];

      if (points && chart === typesChart.STOCKINGS) {
        // eslint-disable-next-line
        for (let index = 0; index < points.length; index++) {
          const item: Point = {
            ...points[index],
            x: getPointX(points[index], chart),
            y: getPointY(points[index], chart, parameter),
          };
          pointsList.push(item);
        }

        dataSource[i].points = pointsList;
        dataSource[i].points?.sort((a, b) => a.x - b.x);
      }

      for (let index = 0; index < avgPoints.length; index++) {
        const item = {
          ...avgPoints[index],
          x: getPointX(avgPoints[index], chart),
          y: getPointY(avgPoints[index], chart, parameter),
        };
        avgPointsList.push(item);
      }

      dataSource[i].avgPoint = avgPointsList;
      dataSource[i].avgPoint?.sort((a, b) => a.x - b.x);
    }
  }

  sortAllPoints () {
    const { dataSource, parameter, chart } = this;
    const pointsList: Point[] = [];

    for (let i = 0; i < dataSource.length; i++) {
      const enabled = dataSource[i].enabled;

      if (!enabled) {
        continue;
      }

      const points = getPoints(dataSource[i], chart);
      if (!points) {
        continue;
      }

      for (let index = 0; index < points.length; index++) {
        const item: Point = {
          ...points[index],
          x: getPointX(points[index], chart),
          y: getPointY(points[index], chart, parameter),
        };
        pointsList.push(item);
      }
    }

    this.allPoints = pointsList;
    this.allPoints.sort((a, b) => a.x - b.x);
  }

  resize = (width: number, height: number) => {
    const { typeMetric, container, isMaturationCurves } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltipComparisionChart').remove();

    this.updateSize(width, height);
    this.sortData(this.dataMetric);
    this.fill = getFill({ dataSource: typeMetric, isMaturationCurves });
    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };

  calculateMovingAverage = () => {
    const { dataSource, movingAverage: window = 2 } = this;

    const movingAverage = [];
    const data: { x: number; y: number }[] = [];

    dataSource.forEach((item) => {
      item.avgPoint.forEach((point) => {
        if (!point?.unusualAverageWeight) {
          data.push({ x: point.x, y: point.y });
        }
      });
    });

    for (let i = window - 1; i < data.length; i++) {
      let sum = 0;
      for (let j = i - window + 1; j <= i; j++) {
        sum += data[j].y;
      }
      const mean = sum / window;
      movingAverage.push({ stage: data[i].x, mean });
    }

    return movingAverage;
  };

  getMovingAverageLine = () => {
    const { x, y } = this;

    const movingAverageLine = d3.line<{ stage: number; mean: number; }>()
      .x((d) => x(d.stage))
      .y((d) => y(d.mean));

    return movingAverageLine;
  };

  renderMovingAverageLine = () => {
    const { gClipPath, showMovingAverage, phaseType, parameter } = this;

    if (!showMovingAverage || (parameter !== typeParam.AVG_WEIGHT) || phaseType !== stockingPhaseTypes.ADULT) {
      return;
    }

    const movingAverageLine = this.getMovingAverageLine();
    const movingAverageData = this.calculateMovingAverage();

    gClipPath.append('path')
      .datum(movingAverageData)
      .attr('id', 'movingAverageLine')
      .attr('class', styles.movingAverageLine)
      .attr('d', movingAverageLine);
  };
}

export default D3ShadedPlot;