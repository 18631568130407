import i18next from 'i18next';

import { roundWeight } from '../../../../config/commons';
import { DataSource, Point, WeightIncrement } from '../../../../pages/home/interfaces';

export const generateAveragePoints = (props: {dataSource: DataSource[]}) => {
  const { dataSource } = props;

  const avgPoints: Point[][] = [];

  for (let index = 0; index < dataSource.length; index++) {
    const avgPoint = dataSource[index].avgPoint;

    avgPoints.push(avgPoint);
  }

  return {
    avgPoints,
  };
};

export const generateWeightIncrement = (props: {dataSource: DataSource[]}) => {
  const { dataSource } = props;
  const { avgPoints } = generateAveragePoints({ dataSource });

  if (avgPoints.length !== 1) {
    return [];
  }

  const points = avgPoints[0];

  const weightIncrements: WeightIncrement[] = points
    .slice(0, -1)
    .map((currentPoint, i) => {
      const nextPoint = points[i + 1];

      const diffStage = nextPoint.x - currentPoint.x;
      const diffAverageWeight = nextPoint.resultData.averageWeight - currentPoint.resultData.averageWeight;

      return {
        currentPoint,
        nextPoint,
        diffStage,
        diffAverageWeight,
        label: `${roundWeight({ value: diffAverageWeight })} (${diffStage}d)`,
      };
    });

  return weightIncrements;
};

export const getWeightIncrement = (props: {selectedPoint: Point; dataSource: DataSource[]}) => {
  const { dataSource, selectedPoint } = props;

  const weightIncrements: WeightIncrement[] = generateWeightIncrement({ dataSource });
  const selectedWeightIncrement = weightIncrements.find((weightIncrement) => weightIncrement.nextPoint.x === selectedPoint.x);
  return selectedWeightIncrement;
};

export const getDayLabel = (props: { diffStage: number }) => {
  const { diffStage } = props;

  if (diffStage === 1) {
    return `(${diffStage} ${i18next.t('shadedplot.day')})`;
  }

  return `(${diffStage} ${i18next.t('shadedplot.days')})`;
};