import cx from 'classnames';
import { Tabs } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { fetchAnalysis } from '../Analysis/sowingSlice';
import { finishStockingTabs } from '../../config/commons';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { getTitleModal } from '../../helpers/finish.stocking.helpers';

import { HarvestForm } from './HarvestForm';
import { TransferForm } from './TransferForm';
import { DiscardedForm } from './DiscardedForm';
import styles from './FinishStocking.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';

interface Props {
  theme?: 'dark' | 'light';
}

const { TabPane } = Tabs;

function FinishStockingModal (props: Props) {
  const { theme = 'dark' } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const [currentTab, setCurrentTab] = useState(finishStockingTabs.HARVEST);

  const {
    hasErrorEdit,
    isLoadingStocking,
    selectedStocking,
  } = useSelector((state: Store) => state.stockings);

  const {
    showModalFinish: showModal,
  } = useSelector((state: Store) => state.finishStocking);

  useEffect(() => {
    const makeFetchAnalysis = pathname !== '/production/stockings';
    if (showModal && selectedStocking._id !== '' && makeFetchAnalysis && !hasErrorEdit) {
      dispatch(fetchAnalysis({ page: 0, stockingId: selectedStocking._id, companyId: selectedStocking.companyId }));
    }
  }, [dispatch, hasErrorEdit, pathname, selectedStocking._id, showModal]);

  return (
    <LrvModal
      theme={theme}
      isLoading={isLoadingStocking}
      className={cx(styles.finishStockingModal, 'finishStockingModal')}
      title={getTitleModal(currentTab)}
      open={showModal}
      destroyOnClose={true}
      onCancel={() => {
        dispatch(finishStockingSlice.setShowModalFinish(false));
        dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
      }}
    >
      <LrvTabs
        theme={theme}
        className={styles.finishStockingTabs}
        activeKey={currentTab}
        defaultActiveKey={currentTab}
        onChange={(activeKey) => {
          setCurrentTab(activeKey);
        }}
      >
        <TabPane className={styles.harvestTab} tab={t('stockings.finishStockingLabels.tabs.harvest')} key={finishStockingTabs.HARVEST}>
          <HarvestForm theme={theme} pathname={pathname} />
        </TabPane>

        <TabPane className={styles.transferTab} tab={t('stockings.finishStockingLabels.tabs.transfer')} key={finishStockingTabs.TRANSFER}>
          <TransferForm theme={theme} pathname={pathname} />
        </TabPane>

        <TabPane className={styles.discardedTab} tab={t('stockings.finishStockingLabels.tabs.discarded')} key={finishStockingTabs.DISCARDED}>
          <DiscardedForm theme={theme} pathname={pathname} />
        </TabPane>
      </LrvTabs>
    </LrvModal>
  );
}

export default FinishStockingModal;
