import { Space, Row, Select } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import { Store } from '../../state/store.interfaces';
import * as campusSlice from '../Units/campusSlice';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { DropdownProps } from '../../common/interfaces/commons';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import IconButton from '../../common/components/buttons/IconButton';
import CleanButton from '../../common/components/buttons/CleanButton';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { LrvFilterPanel } from '../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { roles, getMainRole, stockingPhaseTypes, typeFetchCampus, stockingsShow, unitStatuses, CONTAINER_LABEL, isTradingRole, typeFetchModule, isParameteristRole } from '../../config/commons';

import { Campus } from './interfaces';
import * as stockingSlice from './sowingsSlice';
import styles from './StockingSubHeader.module.scss';
import StockingSearchDesktop from './StockingSearchDesktop';

const { Option } = Select;

interface Props {
  searchStockingsByName: (value: string) => void;
}

function StockingSubHeader ({ searchStockingsByName }: Props) {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tankId = useSelector((state: Store) => state.stockings.selectedTankId);
  const moduleId = useSelector((state: Store) => state.stockings.selectedModuleId);
  const selectedCampus = useSelector((state: Store) => state.stockings.selectedCampus);
  const maturationId = useSelector((state: Store) => state.stockings.selectedMaturationId);
  const maturationCodeId = useSelector((state: Store) => state.stockings.selectedMaturationCodeId);

  const {
    sorts,
    maturations: maturationsFilter,
    maturationCodes: maturationCodesFilter,
    searchValue,
    searchType,
    stockingsToShow,
    tanks: tanksFilter,
    modules: modulesFilter,
    campuses: campusesFilter,
  } = useSelector((state: RootState) => state.stockings.filters);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);
  const { company, phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);

  const userSession = getUserSession();
  const theme = getCurrentTheme();

  //#region React hooks
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  //#endregion

  //#region Commons functions
  const hasPermissionXlsxReport = () => {
    const role = getMainRole();

    return (
      (
        (role === roles.SUPER_ADMIN || role === roles.SALES || role === roles.SALES_MANAGER) ||
        (company.allowXlsxAnalysisReport && (role === roles.CLIENT_OWNER || userSession.allowXlsxAnalysisReport))
      )
    );
  };

  const renderXlsxAnalysisReport = () => {
    const showXlsxOption = hasPermissionXlsxReport();

    if (!showXlsxOption) {
      return null;
    }

    return (
      <IconButton
        id='xlsx_report_button'
        className={styles.reportButton}
        icon={<Icon name='download' theme={theme} />}
        onClick={() => dispatch(stockingSlice.setShowXlsxReport(true))}
        placement='bottomLeft'
        tooltipText={t('stockings.xlsxReport.tooltitpTitle')}
      />
    );
  };

  const renderCreateStockingButton = () => {
    if (isParameteristRole()) {
      return null;
    }

    return (
      <ActionButton
        id='btn_create_stocking'
        className={styles.addButton}
        type='primary'
        onClick={() => {
          dispatch(stockingSlice.fetchCampuses({ type: typeFetchCampus.FORM }));
          dispatch(stockingSlice.fetchMaturations(company._id ? company._id : undefined));
          dispatch(campusSlice.fetchVolumeRanges());
          dispatch(stockingSlice.fetchStockingDateRanges());
          dispatch(stockingSlice.fetchStageOptions());

          switch (phaseTypeSelected) {
            case stockingPhaseTypes.LARVAE:
              dispatch(stockingSlice.setShowCreateModalLarvae(true));
              break;

            case stockingPhaseTypes.JUVENILE:
              dispatch(stockingSlice.setShowCreateModalJuvenile(true));
              break;

            case stockingPhaseTypes.ADULT:
              dispatch(stockingSlice.setShowCreateModalGrowOut(true));
              break;
          }

          if (isRunningOnboarding) {
            dispatch(goToOnboardingNextStep(500));
          }
        }}
        icon={<Icon name='add' />}
      >
        {windowWidth > 1420 ? t('stockings.create') : ''}
      </ActionButton>
    );
  };

  const renderUnitsFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campus'
        showSearch
        theme={theme}
        className={className}
        value={selectedCampus?._id ? selectedCampus._id : undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        onChange={(value) => {
          const campus: Campus | undefined = campusesFilter.find((campus: Campus) => campus._id === value);
          if (!campus?._id) {
            return;
          }

          dispatch(stockingSlice.setCampusSelected(campus));
          dispatch(stockingSlice.setModuleSelected(undefined));
          dispatch(stockingSlice.setTankSelected(undefined));
          dispatch(stockingSlice.fetchModules(value, typeFetchModule.FILTER));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow,
            campusId: value,
            maturationId,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(stockingSlice.fetchStockings(params));
        }}
        filterOption={filterOptionSelect}
      >
        {campusesFilter.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag type='info'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModulesFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        optionFilterProp='children'
        disabled={!selectedCampus?._id}
        onChange={(value) => {
          dispatch(stockingSlice.setModuleSelected(value));
          dispatch(stockingSlice.setTankSelected(undefined));

          dispatch(stockingSlice.fetchTanks(selectedCampus?._id, value, typeFetchModule.FILTER));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow,
            campusId: selectedCampus?._id,
            moduleId: value,
            maturationId,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(stockingSlice.fetchStockings(params));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {modulesFilter.filter((module) => module.phaseType === phaseTypeSelected).map((module) =>
          <Option key={module._id} value={module._id}>{module.name}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderTanksFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_tanks'
        value={tankId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
        placeholder={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
        optionFilterProp='children'
        onChange={(value) => {
          dispatch(stockingSlice.setTankSelected(value));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow,
            campusId: selectedCampus?._id,
            moduleId: moduleId,
            tankId: value,
            maturationId,
            maturationCodeId,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(stockingSlice.fetchStockings(params));
        }}
        filterOption={filterOptionSelect}
        disabled={!(selectedCampus?._id && moduleId)}
        dropdownMatchSelectWidth={false}
      >
        {tanksFilter.map((tank) => <Option key={tank._id} value={tank._id}>{tank.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderMaturationsFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_maturations'
        value={maturationId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t('stockings.maturation')}
        placeholder={t('stockings.maturation')}
        optionFilterProp='children'
        onChange={(value) => {
          dispatch(stockingSlice.setMaturationSelected(value));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow,
            campusId: selectedCampus?._id,
            moduleId: moduleId,
            tankId,
            maturationId: value,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };

          dispatch(stockingSlice.fetchStockings(params));
          dispatch(stockingSlice.fetchGeneticsCodeFilter(value));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {maturationsFilter.map((maturations) =>
          <Option key={maturations._id} value={maturations._id}>{maturations.name}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderMaturationCodesFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_maturation_codes'
        value={maturationCodeId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t('stockings.maturationCode')}
        placeholder={t('stockings.maturationCode')}
        optionFilterProp='children'
        disabled={!maturationId}
        onChange={(value) => {
          dispatch(stockingSlice.setMaturationCodeSelected(value));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow,
            campusId: selectedCampus?._id,
            moduleId: moduleId,
            tankId,
            maturationId,
            maturationCodeId: value,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };

          dispatch(stockingSlice.fetchStockings(params));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {maturationCodesFilter.map((maturation) =>
          <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderRadioOptions = (props: DropdownProps) => {
    const { className, theme } = props;
    const showTradingRadioBtn = isTradingRole() && phaseTypeSelected === stockingPhaseTypes.LARVAE;

    return (
      <LrvSelect
        id='stockings_status_radio_group'
        value={stockingsToShow}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t('stockings.title')}
        placeholder={t('stockings.title')}
        optionFilterProp='children'
        onChange={(value) => {
          dispatch(stockingSlice.setStockingsToShow(value));

          const params = {
            page: 1,
            companyId: company._id,
            searchValue,
            searchType,
            stockingsToShow: value,
            campusId: selectedCampus?._id,
            moduleId: moduleId,
            tankId,
            maturationId,
            maturationCodeId,
            phaseType: phaseTypeSelected,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(stockingSlice.fetchStockings(params));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        <Option key={stockingsShow.ACTIVE} value={stockingsShow.ACTIVE}>{t('stockings.stockingRadio.active')}</Option>
        {
          showTradingRadioBtn &&
          <Option key={stockingsShow.OFFERED} value={stockingsShow.OFFERED}>{t('stockings.stockingRadio.offered')}</Option>
        }
        <Option key={stockingsShow.ALL} value={stockingsShow.ALL}>{t('stockings.stockingRadio.all')}</Option>
      </LrvSelect>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(stockingSlice.resetStockingFilters({ page: 1, companyId: company._id, phaseType: phaseTypeSelected, stockingsToShow: stockingsShow.ACTIVE }));
        }}
      />
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<div className={styles.title}>{t('stockings.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(stockingSlice.resetStockingFilters({ page: 1, companyId: company._id, phaseType: phaseTypeSelected, stockingsToShow: stockingsShow.ACTIVE }));
            },
          }}
        >
          <Space
            className={styles.bodyPanel}
            direction='vertical'
          >
            {renderUnitsFilter({ theme: 'light' })}
            {renderModulesFilter({ theme: 'light' })}
            {renderTanksFilter({ theme: 'light' })}
            {renderRadioOptions({ theme: 'light' })}
            {renderMaturationsFilter({ theme: 'light' })}
            {renderMaturationCodesFilter({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  return (
    <Row className={styles.rowHeader} >
      <Space className={styles.filters} align='end'>
        {renderUnitsFilter({ className: styles.select, theme })}
        {renderModulesFilter({ className: styles.select, theme })}
        {renderTanksFilter({ className: styles.select, theme })}
        {renderRadioOptions({ className: styles.select, theme })}
        {renderMaturationsFilter({ className: styles.select, theme })}
        {renderMaturationCodesFilter({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}

      <Row className={styles.rowRight}>
        <Space align='center' size='middle'>
          <StockingSearchDesktop searchStockingsByName={searchStockingsByName} />
          {renderXlsxAnalysisReport()}
          {renderCreateStockingButton()}
        </Space>
      </Row>
    </Row>
  );
}

export default StockingSubHeader;
