import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Stocking, StockingInformation } from '../interfaces';
import { sortByName } from '../../../utils/sort';
import { Company } from '../../AppHeader/interfaces';
import { Analysis } from '../../Analysis/interfaces';
import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { setPhaseTypesLegend, setPhaseTypesSelected } from '../Multiphase/multiphaseSlice';
import { analysisStatuses, stockingStatuses, unitStatuses } from '../../../config/commons';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';
import { ANALYSES_URL, CAMPUS_URL, MODULES_URL, REFERENCE_CURVES_BY_FILTERS_URL, REFERENCE_CURVES_URL, STOCKINGS_URL, STOCKINGS_WITHOUT_ANALYSIS_URL, STOCKING_COMPARISON_PDF_URL, TANKS_URL } from '../../../config/config.api';

import { ReferenceCurves } from './interfaces';
import { Container, MaturationsInfo, Module, StockingAnalysisState, Unit } from './interfaces';

const initialState: StockingAnalysisState = {
  units: [],
  modules: [],
  containers: [],
  stockings: [],
  maturationsInfo: {
    maturationCodes: [],
    maturations: [],
  },
  isLoadingFetchStockings: false,
  isLoadingFetchActiveStockings: false,
  browsingFromStocking: false,
  searchStocking: '',
  showMultiphaseChart: false,
  reference: undefined,
  parameter: typeParam.AVG_WEIGHT,
  movingAverage: 2,
  showMovingAverage: true,
  showDerivative: false,
  scale: typeScale.LINEAR,
  firstStage: 0,
  lastStage: 0,
  firstStageZoom: 0,
  lastStageZoom: 0,
  maxStage: 0,
  isDownloadingFile: false,
  isVisibleFilterPanel: false,
  showStockingsByContainerModal: false,
  showStockingInformationModal: false,
  isLoadingStockingInformation: false,
  showOtherStockingsModal: false,
  showStockingDetail: false,
  enabledStockings: [],
  listStockingsIds: [],
  listStockingsName: [],
  listStockingsDensity: [],
  referenceCurves: {
    _id: '',
    campusId: '',
    companyId: '',
    name: '',
    phaseType: '',
    values: [],
    type: '',
  },
  globalReferenceCurves: [],
  companyReferenceCurves: [],
  unitReferenceCurves: [],
};

export const stockingAnalysisSlice = createSlice({
  name: 'stockingAnalysis',
  initialState,
  reducers: {
    setUnits: (state: StockingAnalysisState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setModules: (state: StockingAnalysisState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setContainers: (state: StockingAnalysisState, action: PayloadAction<Container[]>) => {
      state.containers = action.payload;
    },
    setStockings: (state: StockingAnalysisState, action: PayloadAction<Stocking[]>) => {
      state.stockings = action.payload;
    },
    setMaturationsInfo: (state: StockingAnalysisState, action: PayloadAction<MaturationsInfo>) => {
      state.maturationsInfo = action.payload;
    },
    setIsLoadingFetchStockings: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingFetchStockings = action.payload;
    },
    setIsLoadingFetchActiveStockings: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingFetchActiveStockings = action.payload;
    },
    setBrowsingFromStocking: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.browsingFromStocking = action.payload;
    },
    setSearchStocking: (state: StockingAnalysisState, action: PayloadAction<string>) => {
      state.searchStocking = action.payload;
    },
    setStocking: (state: StockingAnalysisState, action: PayloadAction<Stocking | undefined>) => {
      state.stockingSelected = action.payload;

      const stockingReferenceCurve = state.stockingSelected?.referenceCurveId;
      if (stockingReferenceCurve?._id) {
        state.reference = stockingReferenceCurve._id;
        state.parameter = stockingReferenceCurve.type;
        state.referenceCurves = stockingReferenceCurve;
      }
    },
    setStockingInformation: (state: StockingAnalysisState, action: PayloadAction<StockingInformation | undefined>) => {
      state.stockingInformation = action.payload;
    },
    setReference: (state: StockingAnalysisState, action: PayloadAction<string | undefined>) => {
      state.reference = action.payload;
    },
    setParameter: (state: StockingAnalysisState, action: PayloadAction<string>) => {
      state.parameter = action.payload;
    },
    setShowMultiphaseChart: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showMultiphaseChart = action.payload;
    },
    setMovingAverage: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.movingAverage = action.payload;
    },
    setShowMovingAverage: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showMovingAverage = action.payload;
    },
    setShowDerivative: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showDerivative = action.payload;
    },
    setFirstStage: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },
    setFirstStageZoom: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.firstStageZoom = action.payload;
    },
    setLastStageZoom: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.lastStageZoom = action.payload;
    },
    setMaxStage: (state: StockingAnalysisState, action: PayloadAction<number>) => {
      state.maxStage = action.payload;
    },
    setScale: (state: StockingAnalysisState, action: PayloadAction<string>) => {
      state.scale = action.payload;
    },
    setIsDownloadingFile: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setUnitSelected: (state: StockingAnalysisState, action: PayloadAction<string | undefined>) => {
      state.unitSelected = action.payload;
    },
    setModuleSelected: (state: StockingAnalysisState, action: PayloadAction<string | undefined>) => {
      state.moduleSelected = action.payload;
    },
    setContainerSelected: (state: StockingAnalysisState, action: PayloadAction<string | undefined>) => {
      state.containerSelected = action.payload;
    },
    setIsVisibleFilterPanel: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.isVisibleFilterPanel = action.payload;
    },
    setShowStockingsByContainerModal: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showStockingsByContainerModal = action.payload;
    },
    setShowOtherStockingsModal: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showOtherStockingsModal = action.payload;
    },
    setShowStockingInformationModal: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showStockingInformationModal = action.payload;
    },
    setIsLoadingStockingInformation: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingStockingInformation = action.payload;
    },
    setLastAnalysis: (state: StockingAnalysisState, action: PayloadAction<Analysis | undefined>) => {
      state.lastAnalysis = action.payload;
    },
    setShowStockingDetail: (state: StockingAnalysisState, action: PayloadAction<boolean>) => {
      state.showStockingDetail = action.payload;
    },

    setListStockingsIds: (state: StockingAnalysisState, action: PayloadAction<string[]>) => {
      state.listStockingsIds = action.payload;
    },
    setListStockingsName: (state: StockingAnalysisState, action: PayloadAction<string[]>) => {
      state.listStockingsName = action.payload;
    },
    setListStockingsDensity: (state: StockingAnalysisState, action: PayloadAction<string[]>) => {
      state.listStockingsDensity = action.payload;
    },
    setEnabledStockings: (state: StockingAnalysisState, action: PayloadAction<boolean[]>) => {
      state.enabledStockings = action.payload;
    },
    setReferenceCurves: (state: StockingAnalysisState, action: PayloadAction<ReferenceCurves>) => {
      state.referenceCurves = action.payload;
    },
    setGlobalReferenceCurves: (state: StockingAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.globalReferenceCurves = action.payload;

      const stockingReferenceCurve = state.stockingSelected?.referenceCurveId;
      if (state.parameter === stockingReferenceCurve?.type) {
        state.reference = stockingReferenceCurve._id;
        state.parameter = stockingReferenceCurve.type;
        state.referenceCurves = stockingReferenceCurve;
        return;
      }

      if (state.globalReferenceCurves.length <= 0) {
        return;
      }

      const globalReferenceCurve = state.globalReferenceCurves[0];
      if (state.parameter === globalReferenceCurve.type) {
        state.reference = globalReferenceCurve._id;
        state.parameter = globalReferenceCurve.type;
        state.referenceCurves = globalReferenceCurve;
        return;
      }
    },
    setCompanyReferenceCurves: (state: StockingAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.companyReferenceCurves = action.payload;
    },
    setUnitReferenceCurves: (state: StockingAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.unitReferenceCurves = action.payload;
    },
  },
});

export const {
  setUnits,
  setModules,
  setContainers,
  setStockings,
  setMaturationsInfo,
  setIsLoadingFetchStockings,
  setIsLoadingFetchActiveStockings,
  setBrowsingFromStocking,
  setSearchStocking,
  setStocking,
  setStockingInformation,
  setReference,
  setShowMultiphaseChart,
  setFirstStage,
  setLastStage,
  setFirstStageZoom,
  setLastStageZoom,
  setMaxStage,
  setParameter,
  setMovingAverage,
  setShowMovingAverage,
  setShowDerivative,
  setScale,
  setIsDownloadingFile,
  setUnitSelected,
  setModuleSelected,
  setContainerSelected,
  setIsVisibleFilterPanel,
  setShowStockingsByContainerModal,
  setShowOtherStockingsModal,
  setShowStockingDetail,
  setShowStockingInformationModal,
  setIsLoadingStockingInformation,
  setLastAnalysis,

  setListStockingsIds,
  setListStockingsName,
  setListStockingsDensity,
  setEnabledStockings,

  setReferenceCurves,
  setGlobalReferenceCurves,
  setCompanyReferenceCurves,
  setUnitReferenceCurves,
} = stockingAnalysisSlice.actions;

export const fetchUnits = (props: { companyId?: string; phaseType: string; modulePhaseType: string; selectFirstItem: boolean }) => async (dispatch: Function) => {
  const { companyId, modulePhaseType, phaseType, selectFirstItem } = props;
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    phaseType,
    $select: ['name'],
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setUnits(response.data));

    if (response.data.length > 0 && selectFirstItem) {
      const unitId = response.data[0]._id;
      dispatch(setUnitSelected(unitId));
      dispatch(fetchModules({ unitId, phaseType: modulePhaseType, selectFirstItem }));
    }
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (props: { unitId: string; phaseType: string; selectFirstItem: boolean }) => async (dispatch: Function) => {
  const { unitId, phaseType, selectFirstItem } = props;

  const params = {
    $limit: -1,
    campusId: unitId,
    phaseType,
    active: true,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setModules(response.data));

    if (response.data.length > 0 && selectFirstItem) {
      const moduleId = response.data[0]._id;
      dispatch(setModuleSelected(moduleId));
      dispatch(fetchContainers({ unitId, moduleId }));
    }
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchContainers = (props: { unitId?: string; moduleId: string }) => async (dispatch: Function) => {
  const { unitId, moduleId } = props;

  const params = {
    campusId: unitId,
    moduleId,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Container[]>(`${TANKS_URL}-analysis-count`, { params: params });
    response.data.sort(sortByName);
    dispatch(setContainers(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

const getParamsStockings = (props: { unitId?: string; moduleId?: string; containerId?: string; companyId?: string; phaseType: string }) => {
  const { unitId, moduleId, containerId, companyId, phaseType } = props;
  const userSession = getUserSession();

  const params = {
    campusId: unitId,
    moduleId,
    tankId: containerId,
    companyId: companyId || userSession.companyId,
    phaseType,
  };

  return params;
};

export const fetchStockings = (props: { unitId?: string; moduleId?: string; containerId?: string; companyId?: string; phaseType: string }) => async (dispatch: Function) => {
  const params = getParamsStockings(props);
  dispatch(setIsLoadingFetchStockings(true));

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_WITHOUT_ANALYSIS_URL, { params });

    dispatch(setStockings(response.data));
    dispatch(setIsLoadingFetchStockings(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchActiveStocking = (props: { unitId?: string; moduleId?: string; containerId?: string; companyId?: string }) => async (dispatch: Function) => {
  const { unitId, moduleId, containerId, companyId } = props;
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    active: true,
    isArchived: false,
    status: stockingStatuses.ACTIVE,
    campusId: unitId,
    moduleId,
    tankId: containerId,
    companyId: companyId || userSession.companyId,
    $populate: ['campusId', 'tankId', 'maturationId', 'moduleId', 'referenceCurveId'],
  };

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_URL, { params });
    const firstStocking = response.data[0];

    dispatch(setStocking(firstStocking));
    dispatch(setIsLoadingFetchActiveStockings(false));
  } catch (e) {
    dispatch(setStocking(undefined));
  }
};

export const fetchStocking = (props: { stockingId: string }) => async (dispatch: Function) => {
  const { stockingId } = props;

  const params = {
    active: true,
    $populate: ['campusId', 'tankId', 'maturationId', 'moduleId', 'referenceCurveId'],
  };
  const url = `${STOCKINGS_URL}/${stockingId}`;

  try {
    const response = await axios.get<Stocking>(url, { params });
    dispatch(setStocking(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

interface ParamsStockingComparisonPdf {
  stockingId: string; stockingName: string;
  dataSource?: string; parameter: string; scale: string;
  firstStage: number; lastStage: number;
  stockingsId?: string; stockingsName?: string;
  movingAverage?: number;
  estimator?: string;
}

export const fetchUrlStockingComparisonPdf = (params: ParamsStockingComparisonPdf) => async (dispatch: Function) => {
  const { stockingId, stockingName, dataSource, parameter, scale, firstStage, lastStage, stockingsId = '', stockingsName = '', movingAverage = -1, estimator } = params;
  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(STOCKING_COMPARISON_PDF_URL);
    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?sowingId=${stockingId}&dataSource=${dataSource}&parameter=${parameter}&scale=${scale}&firstStage=${firstStage}&lastStage=${lastStage}&accessToken=${accessToken}&language=${language}&sowingsId=${stockingsId}&sowingsName=${stockingsName}&movingAverage=${movingAverage}&estimator=${estimator}`;
    await downloadFile(url, stockingName, 'pdf');
  } catch (e) {
    console.log(e.response);
  }

  dispatch(setIsDownloadingFile(false));
};

export const resetChartFilters = (props: { company: Company; phaseType: string }) => (dispatch: Function) => {
  const { company, phaseType } = props;

  dispatch(setUnitSelected(undefined));
  dispatch(setModuleSelected(undefined));
  dispatch(setContainerSelected(undefined));
  dispatch(setStocking(undefined));

  dispatch(setParameter(typeParam.AVG_WEIGHT));
  dispatch(setScale(typeScale.LINEAR));
  dispatch(setShowMultiphaseChart(false));
  dispatch(setPhaseTypesLegend([]));
  dispatch(setPhaseTypesSelected([]));

  dispatch(setContainers([]));
  dispatch(setModules([]));

  const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
  dispatch(fetchUnits({ companyId: company._id, phaseType: unitPhaseType, modulePhaseType: phaseType, selectFirstItem: true }));
};

export const fetchStockingInformation = (props: { stockingId: string, companyId: string }) => async (dispatch: Function) => {
  const { stockingId, companyId } = props;

  const stockingParams = {
    active: true,
    $populate: ['campusId', 'tankId', 'maturationId', 'moduleId'],
  };

  const analysisParams = {
    $limit: 1,
    '$sort[createdAt]': -1,
    'sowingId': stockingId,
    'status': analysisStatuses.COMPLETED,
    '$select': ['code', 'createdAt', 'inputData.stage', 'resultData'],
    isArchived: false,
    'trayId[$exists]': false,
  };

  const url = `${STOCKINGS_URL}/${stockingId}/stocking-info`;
  dispatch(setIsLoadingStockingInformation(true));

  try {
    const stockingResponse = await axios.get<StockingInformation>(url, { params: stockingParams });
    dispatch(setStockingInformation(stockingResponse.data));

    if (stockingResponse.data.companyId !== companyId) {
      dispatch(setLastAnalysis(undefined));
      dispatch(setIsLoadingStockingInformation(false));
      return;
    }

    const analysisResponse = await axios.get(ANALYSES_URL, { params: analysisParams });
    if (analysisResponse.data?.data.length > 0) {
      dispatch(setLastAnalysis(analysisResponse.data?.data[0]));
    }
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsLoadingStockingInformation(false));
};

export const fetchMaturationsInfo = (params: { stockingId: string }) => async (dispatch: Function) => {
  const { stockingId } = params;
  const url = `${STOCKINGS_URL}/${stockingId}/maturations-info`;
  dispatch(setIsLoadingStockingInformation(true));

  try {
    const response = await axios.get(url);
    dispatch(setMaturationsInfo(response.data));
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsLoadingStockingInformation(false));
};

export const fetchGlobalReferenceCurves = (props: { phaseType: string; type: string }) => async (dispatch: Function) => {
  const { phaseType, type } = props;

  const params = {
    $limit: -1,
    phaseType,
    type,
    'companyId[$exists]': false,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, { params });
    dispatch(setGlobalReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyReferenceCurves = (props: { companyId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setCompanyReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnitsReferenceCurves = (props: { companyId: string; campusId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    active: true,
  };

  try {
    const response = await axios.get(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setUnitReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchReferenceCurve = (props: { _id: string; token?: string }) => async (dispatch: Function) => {
  const { _id, token } = props;

  dispatch(setReferenceCurves({ _id: '', name: '', campusId: '', companyId: '', phaseType: '', type: '', values: [] }));

  const url = `${REFERENCE_CURVES_URL}/${_id}`;

  try {
    if (token) {
      const response = await axios.get<ReferenceCurves>(url, {
        headers: { 'Authorization': token },
      });

      dispatch(setReferenceCurves(response.data));
      return;
    }

    const response = await axios.get<ReferenceCurves>(url);
    dispatch(setReferenceCurves(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default stockingAnalysisSlice.reducer;
